<script setup lang="ts">
import { HeaderMobileMenu } from "#components";
import { useModal } from "vue-final-modal";

// mobileメニューモーダル
const { open, close } = useModal({
  component: HeaderMobileMenu,
  attrs: {
    onClose: () => {
      close();
    },
  },
});
</script>

<template>
  <!-- header -->
  <header id="l-header">
    <div class="p-header">
      <HeaderLogo />
      <div class="p-header__menu">
        <FavoriteOpen class="mr-2 pc:hidden" />
        <button class="mr-2 pc:hidden" @click="open">
          <Icon size="32" name="mdi:menu" />
          <span class="block text-xs">メニュー</span>
        </button>
        <ul class="p-header__menu--top mb-4">
          <li class="text-black shadow-none mr-8">
            <HeaderTelNumber />
          </li>
          <li class="p-header__button p-header__button--top mr-4">
            <NuxtLink to="/inquiry/form" class="menuLink">
              <Icon size="18" name="mdi:email-outline" />
              <p>お問い合せ</p>
            </NuxtLink>
          </li>

          <li class="p-header__button p-header__button--top mr-4">
            <NuxtLink to="/contents.php/kouji" class="menuLink">
              <Icon size="18" name="mdi:currency-jpy" />
              <p>単価一覧</p>
            </NuxtLink>
          </li>
          <li class="p-header__button p-header__button--top mr-4">
            <FavoriteOpen :text_size="16" :icon_size="16" class="menuLink" />
          </li>
        </ul>
        <HeaderLink />
      </div>
    </div>
  </header>
</template>

<style scoped>
#l-header {
  @apply sticky top-0 z-40 bg-white;
  background: linear-gradient(#fff 99%, #ebebeb 1%);
}

.p-header {
  @apply flex items-center justify-between;

  &__button {
    &--top {
      @apply text-white bg-famitei-green rounded-b-lg drop-shadow-lg;

      .menuLink {
        @apply flex px-8 py-2 items-center gap-2 w-full h-full;
      }
    }
  }

  &__menu {
    @apply rounded;
    /**ヘッダーメニュー上側 */
    &--top {
      @apply hidden pc:flex pc:justify-end;
    }
  }

  &__tel {
    @apply text-lg leading-3;
  }
  &__time {
    @apply text-[9px] whitespace-nowrap;
  }
}
</style>
