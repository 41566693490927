<script setup lang="ts">
const { footerMenu } = await useMenu();
</script>

<template>
  <ul class="p-footer__list">
    <li v-for="link in footerMenu" :key="link.name" class="p-footer__item">
      <NuxtLink
        :to="link.to"
        :external="link.external"
        class="flex items-center justify-between"
      >
        <p class="mr-1">{{ link.name }}</p>
        <Icon
          v-if="link.external"
          class="text-white mr-2"
          name="mdi:open-in-new"
        />
      </NuxtLink>
    </li>
  </ul>
</template>
<style scoped>
.p-footer {
  /** ページ一覧 */
  &__list {
    @apply flex justify-start flex-wrap border-t tablet:border-none;
  }
  &__item {
    @apply w-1/2 border-b border-r p-2  tablet:border-none hover:bg-green-800;
  }
}
</style>
